import React, { Component } from 'react';
import { Button, Divider, Form, Input, Icon } from 'semantic-ui-react'
import Logo from '../../components/logo/Logo'
import '../../assets/css/forgot-password.css';
import toastr from "toastr";
import Env from '../../services/Env';
import _ from 'lodash';
import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";

const override = css`
display: block;
margin: 0 auto;
border-color: #1890ff;
text-align: center;
position: relative;
top: 25px;
`;


export default class ForgotPassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fields: {
                email_id: ''
            },
            loading: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const name = event.target.dataset.hasOwnProperty("name") ? event.target.dataset.name : event.target.name;
        let value = event.target.value;
        if (value && (event.target.type === "radio" || event.target.type === "checkbox") && typeof value === "string" && (value.toLowerCase() === "true" || value.toLowerCase() === "false")) {
            value = value.toLowerCase() === "true" ? true : false;
        }
        this.updateFormFieldsState(name, value);
    }

    updateFormFieldsState(name, value) {
        if (this && name) {
            let fields = JSON.parse(JSON.stringify(this.state.fields));
            fields = _.set(fields, name, value);
            this.setState({ fields: fields })
        }
    }

    handleSubmit(value) {
        this.setState({ loading: true })
        const postData = Env.post('forgot/password', this.state.fields);
        postData.then(response => {
            const data = response.data
            if (data.status === 200) {
                toastr.success("Email Sent Successfull !!")
                this.props.history.push('/login')
            }
            this.setState({ loading: false })
        }).catch(error => {
            this.setState({ loading: true })
            toastr.error("Failed to Sent Mail")
            this.setState({ loading: false })
        })
    }

    render() {
        const { fields } = this.state;
        return (
            <div className="main" >
                <div className="center-view">
                    <Logo />
                </div>
                <Divider />
                {this.state.loading === true && <ScaleLoader css={override} color={"#153659"} loading={true} />}
                {this.state.loading === false && <div className="card-div" >
                        <div className="inner-card-div">
                            <div className="card-header">
                                <span className="forgot-password-text">Forgot your password ?</span>
                            </div>
                            <div className="center-view">

                                <Form onSubmit={this.handleSubmit}>
                                    <Form.Field>
                                        <Input placeholder='Email ID' type="text" onChange={this.handleChange} value={fields.email_id} name="email_id" required iconPosition='left' icon={<Icon name='mail' />} />
                                    </Form.Field>
                                    <Form.Field>
                                        <Button type="primary" htmlType="submit" size='large' fluid primary> SUBMIT </Button>
                                    </Form.Field>
                                    <Form.Field>
                                        <a className="login-link" href="/login"> <Icon name='arrow left' /> Return to login</a>
                                    </Form.Field>
                                </Form>
                            </div>
                        </div>
                    </div>}
                <div className="footer">
                    <p>Copyright &copy; 2021 Careers Cloud. All rights reserved</p>
                </div>
            </div>
        )
    }
}
