
import AxiosInstance from './Interceptor';

const apiUrl = process.env.REACT_APP_API_URL

const Env = {

    get(path, params) {
        let api = AxiosInstance.get(apiUrl + path, {
            params: params
        })
        return api;
    },

    post(path, payload) {
        let api = AxiosInstance.post(apiUrl + path, payload)
        return api;
    },

    put(path, payload) {
        let api = AxiosInstance.put(apiUrl + path, payload)
        return api;
    }

}

export default Env;