import React, { Component } from 'react';
import Env from '../../services/Env';
import '../../assets/css/common.css';
import moment from 'moment';
import StorageConfiguration from "../../services/StorageConfiguration";
import { Select, Table, Input, Button, Pagination, Dropdown } from 'semantic-ui-react';
import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";
import RangeDatepicker from '../../components/common/DateRangePicker';
import NoRecords from '../../components/common/NoRecords';

const override = css`
display: block;
margin: 0 auto;
border-color: #1890ff;
text-align: center;
position: relative;
`;

class ReferralList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            startDate: null,
            endDate: null,
            endCompletedDate: null,
            order_completed_date: null,
            page: 1,
            rowsPerPage: 10,
            order_id: '',
            coupon_code: "",
            payment_status: "all",
            partner_name: "",
            product_name: "",
            partner_student_name: "",
            sale_amount: "",
            commission_amount: "",
            paid_status: "all",
            partner_id: StorageConfiguration.sessionGetItem('id'),
            filters: true,
            dataSource: [],
            totalRecords: 0,
            loading: false
        };
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    componentDidMount() {
        this.getPromocodeList()
        this.getReferralList()
    }

    getPromocodeList() {
        const params = {
            partner_id: StorageConfiguration.sessionGetItem('id'),
        }
        const promocodeData = []
        const getData = Env.get('partner/partnerpromocodelist', params);
        getData.then(response => {
            const data = response.data.response.promo_code;
            data.forEach((element, index) => {
                promocodeData.push({ key: element.promo_code, value: element.promocode_data, text: element.promocode_data.toUpperCase() })
            })
            promocodeData.unshift({ key: 'all', value: 'all', text: 'ALL' })
        })
        this.promocode = promocodeData
    }

    getReferralList() {
        const params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            page: this.state.page,
            rowsPerPage: this.state.rowsPerPage,
            order_id: this.state.order_id,
            coupon_code: this.state.coupon_code,
            payment_status: this.state.payment_status,
            partner_name: this.state.partner_name,
            product_name: this.state.product_name,
            partner_student_name: this.state.partner_student_name,
            sale_amount: this.state.sale_amount,
            commission_amount: this.state.commission_amount,
            partner_id: StorageConfiguration.sessionGetItem('id'),
            filters: true,
            endCompletedDate: this.state.endCompletedDate,
            order_completed_date: this.state.order_completed_date,
            paid_status: this.state.paid_status
        }
        const dataSource = [];
        this.setState({ loading: true })
        const getData = Env.get('partner/getpartnerreferallist', params);
        getData.then(response => {
            const data = response.data.response.partner_referallist.data
            data.forEach((element, index) => {
                dataSource.push({
                    sno: response.data.response.partner_referallist.from + index,
                    payment_id: element.order_id,
                    order_initiated_date: moment(element.created_at).format('DD-MM-yyyy hh:mm A'),
                    order_completed_date: element.order_completed_date !== null ? moment(element.order_completed_date).format('DD-MM-yyyy hh:mm A') : '',
                    coupon_code: [element.promocode_data.toUpperCase()],
                    payment_status: element.payment_status,
                    partner_name: element.users_firstname + ' ' + element.users_lastname,
                    student_last_name: element.student_first_name ? (element.student_first_name + ' ' + element.student_last_name) : '',
                    product_name: element.product_title,
                    sale_amount: element.final_price,
                    commission_amount: (element.final_price * element.commission_rate) / 100,
                    paid_status: element.paid_status,
                    key: index + 1
                })
            })
            this.setState({ dataSource: dataSource, totalRecords: response.data.response.partner_referallist.total, loading: false })
        })
    }

    handleDatePicker = (event) => {
        if (event !== null) {
            if (event[0] !== '') {
                this.setState({ startDate: moment(event[0]).format('YYYY-MM-DD'), endDate: moment(event[1]).format('YYYY-MM-DD'), filters: true })
            }
        }
        else {
            this.setState({ startDate: '', endDate: '' }, () => this.getReferralList())
        }
    }

    handleClearFilter(e) {
        this.setState({
            startDate: '',
            endDate: '',
            page: 1,
            rowsPerPage: 10,
            order_id: '',
            coupon_code: '',
            payment_status: 'all',
            partner_name: '',
            product_name: '',
            partner_student_name: '',
            sale_amount: '',
            commission_amount: '',
            partner_id: StorageConfiguration.sessionGetItem('id'),
            filters: true,
            order_completed_date: '',
            endCompletedDate: '',
            paid_status: 'all',
        }, () => this.getReferralList())
    }

    handleFilterChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    handlePageChange(event, data) {
        this.setState({ page: data.activePage }, () => this.getReferralList())
    }

    handleSelectChange(event, data) {
        this.setState({ [data.name]: data.value }, () => this.getReferralList())
    }

    handleKeyDown(event) {
        if (event.keyCode === 13 || event.which === 13) {
            this.getReferralList();
        }
    }

    handleDateApply = (startDate, endDate, props) => {
        if (startDate !== '' && endDate !== '') {
            if (props.name === 'initial_date') {
                this.setState({ startDate: startDate, endDate: endDate }, () => this.getReferralList());
            }
            if (props.name === 'completed_date') {
                this.setState({ order_completed_date: startDate, endCompletedDate: endDate }, () => this.getReferralList());
            }
        }
        if (startDate === '' && endDate === '') {
            this.setState({ order_completed_date: '', endCompletedDate: '', startDate: '', endDate: '' });
        }
    };

    render() {

        return (
            <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><span>Home</span></li>
                        <li className="breadcrumb-item active" aria-current="page">Referral List ({this.state.totalRecords})</li>
                    </ol>
                </nav>

                <div className="clear-button-view">
                    {/* {this.state.loading === true && <ScaleLoader css={override} color={"#153659"} loading={true} />} */}
                    <div className="clear-button">
                        {this.state.dataSource && <Button onClick={(e) => this.handleClearFilter(e)} size="tiny">Clear Filter</Button>}
                    </div>
                </div>

                <div>
                    <div className="table-list">
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    {
                                        columns.map((item, index) => <Table.HeaderCell key={index}>{item.title}</Table.HeaderCell>)
                                    }
                                </Table.Row>
                            </Table.Header>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell>
                                        <Input type="text" placeholder="Order ID" name="order_id" value={this.state.order_id} onChange={this.handleFilterChange} onKeyDown={this.handleKeyDown} />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <RangeDatepicker
                                            {...this.props}
                                            handleDateApply={this.handleDateApply}
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            name="initial_date"
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <RangeDatepicker
                                            {...this.props}
                                            handleDateApply={this.handleDateApply}
                                            order_completed_date={this.state.order_completed_date}
                                            endCompletedDate={this.state.endCompletedDate}
                                            name="completed_date"
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell><Dropdown placeholder='Select promocode' selection options={this.promocode} name="coupon_code" value={this.state.coupon_code} onChange={this.handleSelectChange} /></Table.HeaderCell>
                                    <Table.HeaderCell><Select placeholder='Select payment status' options={status} onChange={this.handleSelectChange} name="payment_status" value={this.state.payment_status} /></Table.HeaderCell>
                                    {/* <Table.HeaderCell><Input type="text" placeholder="Partner Name" name="partner_name" value={this.state.partner_name} onChange={this.handleFilterChange} onKeyDown={this.handleKeyDown} /></Table.HeaderCell> */}
                                    <Table.HeaderCell><Input type="text" placeholder="Student Name" name="partner_student_name" value={this.state.partner_student_name} onChange={this.handleFilterChange} onKeyDown={this.handleKeyDown} /></Table.HeaderCell>
                                    <Table.HeaderCell><Input type="text" placeholder="Product Name" name="product_name" value={this.state.product_name} onChange={this.handleFilterChange} onKeyDown={this.handleKeyDown} /></Table.HeaderCell>
                                    <Table.HeaderCell><Input type="text" placeholder="Sale Amount" name="sale_amount" value={this.state.sale_amount} onChange={this.handleFilterChange} onKeyDown={this.handleKeyDown} /></Table.HeaderCell>
                                    <Table.HeaderCell><Input type="text" placeholder="Commission Amount" name="commission_amount" value={this.state.commission_amount} onChange={this.handleFilterChange} onKeyDown={this.handleKeyDown} /></Table.HeaderCell>
                                    <Table.HeaderCell><Dropdown placeholder='Paid Status' selection options={paidStatus} name="paid_status" value={this.state.paid_status} onChange={this.handleSelectChange} /></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {
                                this.state.dataSource.length !== 0 ? <Table.Body>
                                    {
                                        this.state.dataSource.map((item, index) => <Table.Row key={index}>
                                            <Table.Cell>{item.sno}</Table.Cell>
                                            <Table.Cell>{item.payment_id}</Table.Cell>
                                            <Table.Cell>{item.order_initiated_date}</Table.Cell>
                                            <Table.Cell>{item.order_completed_date}</Table.Cell>
                                            <Table.Cell>{item.coupon_code}</Table.Cell>
                                            <Table.Cell>{item.payment_status}</Table.Cell>
                                            {/* <Table.Cell>{item.partner_name}</Table.Cell> */}
                                            <Table.Cell>{item.student_last_name}</Table.Cell>
                                            <Table.Cell>{item.product_name}</Table.Cell>
                                            <Table.Cell>{item.sale_amount}</Table.Cell>
                                            <Table.Cell>{item.commission_amount}</Table.Cell>
                                            <Table.Cell>{item.paid_status}</Table.Cell>
                                        </Table.Row>)
                                    }
                                </Table.Body> : <Table.Body>
                                    <Table.Cell colSpan="11">
                                    { this.state.loading === false && <NoRecords /> }
                                    { this.state.loading === true && <ScaleLoader css={override} color={"#153659"} loading={true} /> }
                                    </Table.Cell>
                                </Table.Body>
                            }

                        </Table>

                    </div>


                    <div className="table-footer">
                        <span style={{ paddingRight: '15px' }}>No. of Entries</span>
                        <Dropdown placeholder='Select page entries' options={entries} selection value={this.state.rowsPerPage} name="rowsPerPage" onChange={this.handleSelectChange} />
                        <Pagination totalPages={Math.round(this.state.totalRecords / this.state.rowsPerPage)} className="table-pagination" onPageChange={this.handlePageChange} siblingRange={1} />
                    </div>
                </div>


            </div>
        )
    }
}

const status = [
    { key: 'all', value: 'all', text: 'All' },
    { key: 'pending', value: 'pending', text: 'Pending' },
    { key: 'processing', value: 'processing', text: 'Processing' },
    { key: 'completed', value: 'completed', text: 'Completed' },
    { key: 'unsubscribed', value: 'unsubscribed', text: 'Unsubscribed' },
    { key: 'cancelled', value: 'cancelled', text: 'Cancelled' },
    { key: 'failed', value: 'failed', text: 'Failed' }
]

const entries = [
    { key: 10, value: 10, text: '10' },
    { key: 25, value: 25, text: '25' },
    { key: 50, value: 50, text: '50' },
    { key: 100, value: 100, text: '100' }
]

const columns = [
    {
        title: 'S No',
        key: 'sno'
    },
    {
        title: 'Order ID',
        key: 'payment_id'
    },
    {
        title: 'Order Initiated Date',
        key: 'order_initiated_date'
    },
    {
        title: 'Order Completed Date',
        key: 'order_completed_date'
    },
    {
        title: 'Coupon Code',
        key: 'coupon_code'
    },
    {
        title: 'Payment Status',
        key: 'payment_status'
    },
    // {
    //     title: 'Partner Name',
    //     key: 'partner_name'
    // },
    {
        title: 'Student Name',
        key: 'student'
    },
    {
        title: 'Product Name',
        key: 'product_name'
    },
    {
        title: 'Sale Amount',
        key: 'sale_amount'
    },
    {
        title: 'Commission Amount',
        key: 'commission_amount'
    },
    {
        title: 'Paid Status',
        key: 'paid_status'
    }
];

const paidStatus = [
    { key: 'all', value: 'all', text: 'All' },
    { key: 'paid', value: 'paid', text: 'Paid' },
    { key: 'unpaid', value: 'unpaid', text: 'Unpaid' },
]

export default ReferralList;