import StorageConfiguration from '../services/StorageConfiguration'

class Auth {

    isAuthenticated() {
        return JSON.parse(StorageConfiguration.sessionGetItem('isloggedIn'));
    }

}

const auth = new Auth()

export default auth;