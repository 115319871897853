import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import Env from '../../services/Env';
import StorageConfiguration from "../../services/StorageConfiguration";
import toastr from 'toastr';
import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";
import '../../assets/css/change-password.css';

const override = css`
display: block;
border-color: #1890ff;
text-align: center;
position: relative;
`;

export default class ChangePassword extends Component {
    constructor() {
        super()
        this.state = {
            current_password: '',
            confirm_password: '',
            new_password: '',
            loading: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const name = event.target.dataset.hasOwnProperty("name") ? event.target.dataset.name : event.target.name;
        let value = event.target.value;
        if (value && (event.target.type === "radio" || event.target.type === "checkbox") && typeof value === "string" && (value.toLowerCase() === "true" || value.toLowerCase() === "false")) {
            value = value.toLowerCase() === "true" ? true : false;
        }
        this.updateFormFieldsState(name, value);
    }

    updateFormFieldsState(name, value) {
        this.setState({ [name]: value })
    }

    handleSubmit() {
        const userId = StorageConfiguration.sessionGetItem('id')
        const payload = {
            current_password: this.state.current_password,
            confirm_password: this.state.confirm_password,
            new_password: this.state.new_password,
        }
        this.setState({ loading: true })
        const updateDate = Env.put(`partner/passwordupdate/${userId}`, payload);
        if (this.state.new_password === this.state.confirm_password) {
            updateDate.then(response => {
                const status = response.data.status;
                if (status === 200) {
                    toastr.success("Password Updated Successfull !!")
                    this.setState({ loading: false })
                    this.props.history.push('/login')
                }
            }, err => {
                toastr.error(err.response.data.message ? err.response.data.message : 'Failed to update the password')
                this.setState({ loading: false })
            })
        }
        else {
            toastr.error('New password & confirm password are not same')
            this.setState({ loading: false })
        }
    }

    render() {
        return (
            <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><span>User</span></li>
                        <li className="breadcrumb-item active" aria-current="page">Change Password</li>
                    </ol>
                </nav>

                <div className="container-fluid">
                    <Form className="change-password-form" onSubmit={this.handleSubmit}>

                        {this.state.loading === true && <ScaleLoader css={override} color={"#153659"} loading={true} />}

                        <Form.Input
                            onChange={this.handleChange}
                            name='current_password'
                            label='Current Password'
                            value={this.state.current_password}
                            type="password"
                            maxLength="16"
                            placeholder='Current password'
                        />

                        <Form.Input
                            onChange={this.handleChange}
                            name='new_password'
                            label='New Password'
                            placeholder='New Password'
                            value={this.state.new_password}
                            type="password"
                            maxLength="16"
                            minLength="6"
                        />

                        <Form.Input
                            onChange={this.handleChange}
                            name='confirm_password'
                            label='Confirm Password'
                            placeholder='Confirm Password'
                            value={this.state.confirm_password}
                            type="password"
                            maxLength="16"
                            minLength="6"
                        />

                        <Form.Field>
                            <NavLink to='/dashboard'><Button size='large'>Cancel</Button></NavLink>
                            <Button size='large' style={{ background: "#2185d0", color: '#fff' }} type="submit">Submit</Button>
                        </Form.Field>

                    </Form>
                </div>
            </div>
        )
    }
}
