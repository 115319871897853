import React from 'react';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

class RangeDatepicker extends React.Component {

  constructor(props) {
    super(props)
    this.handleApply = this.handleApply.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleApply(event, picker) {
    const startDate = moment(picker.startDate).format('YYYY-MM-DD');
    const endDate = moment(picker.endDate).format('YYYY-MM-DD');
    this.props.handleDateApply(startDate, endDate, this.props);
  }

  handleCancel() {
    this.props.handleDateApply('', '');
  }

  render() {

    const datepicker = (this.props.name === 'initial_date' ? <DateRangePicker onApply={this.handleApply} onCancel={this.handleCancel}>
      <input style={styles.sematicUIField} value={this.props.startDate ? this.props.startDate + '-' + this.props.endDate : ''} placeholder="Start Date - End Date" readOnly={true} />
    </DateRangePicker> : <DateRangePicker onApply={this.handleApply} onCancel={this.handleCancel}>
        <input style={styles.sematicUIField} value={this.props.order_completed_date ? this.props.order_completed_date + '-' + this.props.endCompletedDate : ''} placeholder="Start Date - End Date" readOnly={true} />
      </DateRangePicker>
    );

    const div = <div className="date-selector more-date-selector">{datepicker}</div>

    return (this.props.divClass ? <div className={this.props.divClass}>{datepicker}</div> : div);
  }
}

export default RangeDatepicker;

const styles = {
  sematicUIField: {
    margin: 0,
    maxWidth: '100%',
    flex: '1 0 auto',
    outline: 0,
    textAlign: 'left',
    lineHeight: '1.21428571em',
    fontFamily: 'Lato,Helvetica Neue,Arial,Helvetica,sans-serif',
    padding: '.67857143em 1em',
    background: '#fff',
    border: '1px solid rgba(34,36,38,.15)',
    color: 'rgba(0,0,0,.87)',
    borderRadius: '.28571429rem',
    transition: 'box-shadow .1s ease,border-color .1s ease',
    boxShadow: 'none'
  }
}