import React, { Component } from 'react'

export default class NoRecords extends Component {
    render() {
        return (
            <div style={{fontSize: 'large'}}>
                No Records Found
            </div>
        )
    }
}
