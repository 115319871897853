import React, { Component } from 'react';
import { Dropdown } from 'semantic-ui-react';
import Env from '../../services/Env';
import StorageConfiguration from '../../services/StorageConfiguration';
import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";
import toastr from 'toastr';
import { Line } from 'react-chartjs-2';
import RangeDatepicker from '../../components/common/DateRangePicker';
import '../../assets/css/report.css';
import NoRecords from '../../components/common/NoRecords';

const override = css`
display: block;
margin: 0 auto;
border-color: #1890ff;
text-align: center;
position: relative;
`;

// const ctx = new Chart(document.getElementById('myChart'));
// this.myChart = new Chart(document.getElementById('myChart')


export default class Reports extends Component {

    constructor(props) {
        super(props)
        this.state = {
            type: 'today',
            referralCount: 0,
            sum_of_commision_rate: 0,
            startDate: null,
            endDate: null,
            loading: false,
            chartData: {},
            paidAmount: 0,
            unPaidAmount: 0,
            cancelledReferrals: 0,
            pendingReferrals: 0
        }
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.id = StorageConfiguration.sessionGetItem('id');
        this.userName = StorageConfiguration.sessionGetItem('firstName') + ' ' + StorageConfiguration.sessionGetItem('lastName');
        this.myChartRef = document.getElementById('myChart');
    }

    componentDidMount() {
        this.getChartData()
    }

    getChartData() {
        const params = {
            partner_id: this.id,
            type: this.state.type,
            startDate: this.state.startDate,
            endDate: this.state.endDate
        }
        this.setState({ loading: true })
        let getData = Env.get('partner/partnerportalgraph', params);
        getData.then(response => {
            let data = response.data.response.partner_graph
            let transactions = response.data.response.partner_graph.totalTransaction ? response.data.response.partner_graph.totalTransaction : response.data.response.partner_graph.partner_graph
            let labels = []
            let earnedAmount = []
            let referralCount = []
            let paidAmount = data.paid_amount;
            let unPaidAmount = data.unpaid_amount;
            let pendingCount = []
            let failedCount = []
            let completedReferrals = []
            for (let i = 0; i < transactions.length; i++) {
                labels.push(transactions[i].xaxis)
                earnedAmount.push(transactions[i].earned_amount)
                referralCount.push(transactions[i].all_referrals)
                // paidAmount.push(transactions[i].paid_amount)
                // unPaidAmount.push(transactions[i].unpaid_amount)
                completedReferrals.push(transactions[i].completed_referrals)
                pendingCount.push(transactions[i].pending_referrals)
                failedCount.push(transactions[i].cancelled_referrals)
            }
            const lineData = {
                labels: labels,
                datasets: [{ label: 'Earned Amount', data: earnedAmount, borderColor: 'rgb(255, 99, 132)', fill: false, pointStyle: 'rect', radius: 4, backgroundColor: 'rgb(255, 99, 132)', hoverRadius: 5, borderWidth: 2, offset: 20 },
                { label: 'Referral Count', data: referralCount, borderColor: 'rgb(75, 192, 192)', pointStyle: 'rect', radius: 4, fill: false, backgroundColor: 'rgb(75, 192, 192)', hoverRadius: 5, borderWidth: 2 },
                { label: 'Pending Count', data: pendingCount, borderColor: 'rgb(54, 162, 235)', pointStyle: 'rect', radius: 4, fill: false, backgroundColor: 'rgb(54, 162, 235)', hoverRadius: 5, borderWidth: 2 },
                { label: 'Cancelled Count', data: failedCount, borderColor: 'rgb(153, 102, 255)', pointStyle: 'rect', radius: 4, fill: false, backgroundColor: 'rgb(153, 102, 255)', hoverRadius: 5, borderWidth: 2 }]
            }
            // 'rgb(255, 99, 132)',
            // 'rgb(255, 159, 64)',
            // 'rgb(255, 205, 86)',
            // 'rgb(75, 192, 192)',
            // 'rgb(54, 162, 235)',
            // 'rgb(153, 102, 255)',
            // 'rgb(201, 203, 207)'
            const reducer = (acc, value) => acc + value;
            this.setState({ sum_of_commision_rate: earnedAmount.reduce(reducer), referralCount: referralCount.reduce(reducer), loading: false, chartData: lineData, paidAmount: paidAmount, unPaidAmount: unPaidAmount, completedReferrals: completedReferrals.reduce(reducer), pendingReferrals: pendingCount.reduce(reducer), cancelledReferrals: failedCount.reduce(reducer) })
        }, err => {
            this.setState({ loading: false, chartData: {} })
            toastr.error('Failed to Retrieve Data')
        })
    }

    handleSelectChange(event, data) {
        if (data.value !== 'custom') {
            this.setState({ [data.name]: data.value }, () => this.getChartData())
        }
        else {
            this.setState({ [data.name]: data.value })
        }
    }

    handleFilter(e) {
        if (this.state.startDate !== null && this.state.endDate !== null) {
            this.getChartData()
        }
        else {
            toastr.error('Choose From Date & To Date')
        }
    }

    handleDateApply = (startDate, endDate, props) => {
        if (startDate !== '' && endDate !== '') {
            if (props.name === 'initial_date') {
                this.setState({ startDate: startDate, endDate: endDate }, () => this.getChartData());
            }
            if (props.name === 'completed_date') {
                this.setState({ order_completed_date: startDate, endCompletedDate: endDate }, () => this.getChartData());
            }
        }
        if (startDate === '' && endDate === '') {
            this.setState({ order_completed_date: '', endCompletedDate: '', startDate: '', endDate: '' });
        }
    };

    render() {
        const lineChartOptions = {
            responsive: true,
            elements: {
                line: {
                    tension: 0
                }
            },
            animation: {
                duration: 0
            },
            hover: {
                animationDuration: 0
            },
            showLines: true,
            responsiveAnimationDuration: 0,
            legend: {
                display: true,
                labels: {
                    fontColor: '#153659',
                    fontSize: 18
                },
                position: 'bottom',
                onHover: function (e) {
                    e.target.style.cursor = 'pointer';
                }
            },
            title: {
                display: false,
                text: 'Partner Sales Report'
            },
            tooltips: {
                mode: "index",
                intersect: true,
                position: 'nearest',
                bodySpacing: 5,
                titleFontSize: 19,
                bodyFontSize: 17,
                backgroundColor: 'grey'
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }],
                xAxes: [{
                    offset: 10
                }]
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                }
            }
        }

        return (
            <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><span>Home</span></li>
                        <li className="breadcrumb-item active" aria-current="page">Sale Report</li>
                    </ol>
                </nav>

                <div style={{ margin: '10px 0px 10px 0px', paddingBottom: '30px' }}>
                    {
                        this.state.type === 'custom' && <div style={{ float: 'right', margin: '5px' }}>
                            <RangeDatepicker
                                {...this.props}
                                handleDateApply={this.handleDateApply}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                name="initial_date"
                            />
                            {/* <RangePicker onCalendarChange={(e) => this.handleDatePicker(e)} /> */}
                            {/* <Button type="primary" onClick={(e) => this.handleFilter(e)} style={{ margin: '0px 0px 0px 15px' }} primary size="tiny">Apply Filter</Button> */}
                        </div>
                    }
                    <div style={{ float: 'right', margin: '5px' }}>
                        <Dropdown
                            name="type"
                            value={this.state.type}
                            placeholder='Search by Filter'
                            selection
                            options={filters}
                            onChange={this.handleSelectChange}
                        />
                    </div>
                </div>

                <div className="container-fluid">
                    <div className="row justify-content-md-center" style={{ width: '100%' }}>
                        <div className="col col-lg-3" style={{ margin: '10px' }}>
                            <div className="card">
                                <div className="card-body">
                                    <span className="card-title">EARNED AMOUNT</span>
                                    <h6 className="card-subtitle mt-2 text-muted">₹ {Math.round(this.state.sum_of_commision_rate)}</h6>
                                </div>
                            </div>
                        </div>

                        <div className="col col-lg-4" style={{ margin: '10px', bottom: '30px' }}>
                            <h5 style={{ textAlign: 'center', color: 'grey' }}>REFERRAL STATUS</h5>
                            <div className="card">
                                <div className="card-body">
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div className="col col-lg-4">
                                            <h6 className="card-title text-center">COMPLETED</h6>
                                            <h6 className="card-subtitle mt-0 text-muted" style={{ textAlign: 'center' }}>{this.state.completedReferrals} nos.</h6>
                                        </div>
                                        <div className="col col-lg-4">
                                            <h6 className="card-title text-center">PENDING</h6>
                                            <h6 className="card-subtitle mt-0 text-muted" style={{ textAlign: 'center' }}>{this.state.pendingReferrals} nos.</h6>
                                        </div>
                                        <div className="col col-lg-4">
                                            <h6 className="card-title text-center">CANCELLED</h6>
                                            <h6 className="card-subtitle mt-0 text-muted" style={{ textAlign: 'center' }}>{this.state.cancelledReferrals} nos.</h6>
                                        </div>
                                    </div>
                                    {/* <span style={{ float: 'left' }}>
                                        <h6 className="card-title">TOTAL REFERRAL</h6>
                                        <h6 className="card-subtitle mb-2 text-muted">{this.state.referralCount} nos.</h6>
                                    </span>
                                    <span style={{ float: 'left' }}>
                                        <h6 className="card-title">TOTAL REFERRAL</h6>
                                        <h6 className="card-subtitle mb-2 text-muted">{this.state.referralCount} nos.</h6>
                                    </span>
                                    <span style={{ float: 'right' }}>
                                        <h6 className="card-title">COMPLETED REFERRAL</h6>
                                        <h6 className="card-subtitle mb-2 text-muted" style={{ textAlign: 'right' }}>{this.state.cancelledReferrals} nos.</h6>
                                    </span> */}
                                </div>
                            </div>
                        </div>

                        <div className="col col-lg-3" style={{ margin: '10px' }}>
                            <div className="card">
                                <div className="card-body">
                                    <span style={{ float: 'left' }}>
                                        <span className="card-title">PAID AMOUNT</span>
                                        <h6 className="card-subtitle mt-2 text-muted">₹ {Math.round(this.state.paidAmount)}</h6>
                                    </span>
                                    <span style={{ float: 'right' }}>
                                        <span className="card-title">UNPAID AMOUNT</span>
                                        <h6 className="card-subtitle mt-2 text-muted" style={{ textAlign: 'right' }}>₹ {Math.round(this.state.unPaidAmount)}</h6>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.loading === true && <ScaleLoader css={override} color={"#153659"} loading={true} />}
                {this.state.loading === false && this.state.paidAmount === 0 && this.state.sum_of_commision_rate === 0 && this.state.pendingReferrals === 0 && this.state.cancelledReferrals === 0 && this.state.unPaidAmount === 0 && <div style={{ margin: '40px', display: 'flex', justifyContent: 'center' }}><NoRecords /></div>}

                <div style={{ margin: '40px' }}>
                    {this.state.loading === false && <Line data={this.state.chartData} options={lineChartOptions} />}
                </div>

            </div>
        )
    }
}

const filters = [
    { key: "today", value: "today", text: "Today" },
    { key: "yesterday", value: "yesterday", text: "Yesterday" },
    { key: "thisweek", value: "thisweek", text: "This Week" },
    { key: "lastweek", value: "lastweek", text: "Last Week" },
    { key: "thismonth", value: "thismonth", text: "This Month" },
    { key: "lastmonth", value: "lastmonth", text: "Last Month" },
    { key: "thisyear", value: "thisyear", text: "This Year" },
    { key: "lastyear", value: "lastyear", text: "Last Year" },
    { key: "custom", value: "custom", text: "Custom" },
];
