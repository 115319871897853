import React, { Component } from 'react';
import Env from '../../services/Env';
import '../../assets/css/common.css';
import moment from 'moment';
import StorageConfiguration from "../../services/StorageConfiguration";
import { Table, Input, Button, Pagination, Dropdown } from 'semantic-ui-react';
import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";
import RangeDatepicker from '../../components/common/DateRangePicker';
import NoRecords from '../../components/common/NoRecords';

const override = css`
display: block;
margin: 0 auto;
border-color: #1890ff;
text-align: center;
position: relative;
`;

export default class PayoutHistory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            startDate: null,
            endDate: null,
            page: 1,
            rowsPerPage: 10,
            commission_amount: "",
            partner_id: StorageConfiguration.sessionGetItem('id'),
            filters: true,
            dataSource: [],
            totalRecords: 0,
            loading: false,
            order_completed_date: "",
            endCompletedDate: '',
            referal_count: ''
        };
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this)
    }

    componentDidMount() {
        this.getPayoutHistory()
    }

    getPayoutHistory() {
        const params = {
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            page: this.state.page,
            rowsPerPage: this.state.rowsPerPage,
            partner_id: StorageConfiguration.sessionGetItem('id'),
            filters: true,
            order_completed_date: this.state.order_completed_date,
            endCompletedDate: this.state.endCompletedDate,
            commission_amount: this.state.commission_amount,
            referal_count: this.state.referal_count
        }
        const dataSource = [];
        this.setState({ loading: true })
        const getData = Env.get('payout/payouthistory', params);
        getData.then(response => {
            const data = response.data.response.payment_details.data
            data.forEach((element, index) => {
                dataSource.push({
                    sno: response.data.response.payment_details.from + index,
                    payout_initiated_date: moment(element.payout_start_date).format('DD-MM-yyyy hh:mm A'),
                    payout_completed_date: element.payout_end_date !== null ? moment(element.payout_end_date).format('DD-MM-yyyy hh:mm A') : '',
                    commission_amount: element.commission_amount,
                    referral_count: element.referal_count,
                    created_at: moment(element.created_at).format('DD-MM-yyyy hh:mm A'),
                    key: index + 1
                })
            })
            this.setState({ dataSource: dataSource, totalRecords: response.data.response.payment_details.total, loading: false })
        })
    }

    handleClearFilter(e) {
        this.setState({
            startDate: '',
            endDate: '',
            page: 1,
            rowsPerPage: 10,
            commission_amount: '',
            partner_id: StorageConfiguration.sessionGetItem('id'),
            filters: true,
            order_completed_date: '',
            endCompletedDate: '',
            referal_count: ''
        }, () => this.getPayoutHistory())
    }

    handleFilterChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    handlePageChange(event, data) {
        this.setState({ page: data.activePage }, () => this.getPayoutHistory())
    }

    handleSelectChange(event, data) {
        this.setState({ [data.name]: data.value }, () => this.getPayoutHistory())
    }

    handleKeyDown(event) {
        if (event.keyCode === 13 || event.which === 13) {
            this.getPayoutHistory();
        }
    }

    handleDateApply = (startDate, endDate, props) => {
        if (startDate !== '' && endDate !== '') {
            if (props.name === 'initial_date') {
                this.setState({ startDate: startDate, endDate: endDate }, () => this.getPayoutHistory());
            }
            if (props.name === 'completed_date') {
                this.setState({ order_completed_date: startDate, endCompletedDate: endDate }, () => this.getPayoutHistory());
            }
        }
        if (startDate === '' && endDate === '') {
            this.setState({ order_completed_date: '', endCompletedDate: '', startDate: '', endDate: '' });
        }
    };

    render() {
        return (
            <div>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><span>Home</span></li>
                        <li className="breadcrumb-item active" aria-current="page">Payout History ({this.state.totalRecords})</li>
                    </ol>
                </nav>

                <div className="clear-button-view">
                    {/* {this.state.loading === true && <ScaleLoader css={override} color={"#153659"} loading={true} />} */}
                    <div className="clear-button">
                        {this.state.dataSource && <Button onClick={(e) => this.handleClearFilter(e)} size="tiny">Clear Filter</Button>}
                    </div>
                </div>

                <div>
                    <div className="table-list">
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    {
                                        columns.map((item, index) => <Table.HeaderCell key={index}>{item.title}</Table.HeaderCell>)
                                    }
                                </Table.Row>
                            </Table.Header>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell>
                                        <RangeDatepicker
                                            {...this.props}
                                            handleDateApply={this.handleDateApply}
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            name="initial_date"
                                        />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell><Input type="text" placeholder="Referral Count" name="referal_count" value={this.state.referal_count} onChange={this.handleFilterChange} onKeyDown={this.handleKeyDown} /></Table.HeaderCell>
                                    <Table.HeaderCell><Input type="text" placeholder="Paid Amount" name="commission_amount" value={this.state.commission_amount} onChange={this.handleFilterChange} onKeyDown={this.handleKeyDown} /></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            {
                                this.state.dataSource.length !== 0 ? <Table.Body>
                                    {
                                        this.state.dataSource.map((item, index) => <Table.Row key={index}>
                                            <Table.Cell>{item.sno}</Table.Cell>
                                            <Table.Cell>{item.created_at}</Table.Cell>
                                            <Table.Cell>{item.payout_initiated_date}</Table.Cell>
                                            <Table.Cell>{item.payout_completed_date}</Table.Cell>
                                            <Table.Cell>{item.referral_count}</Table.Cell>
                                            <Table.Cell>{item.commission_amount}</Table.Cell>
                                        </Table.Row>)
                                    }
                                </Table.Body> : <Table.Body>
                                    <Table.Cell colSpan="11">
                                        {this.state.loading === false && <NoRecords />}
                                        {this.state.loading === true && <ScaleLoader css={override} color={"#153659"} loading={true} />}
                                    </Table.Cell>
                                </Table.Body>
                            }

                        </Table>

                    </div>


                    <div className="table-footer">
                        <span style={{ paddingRight: '15px' }}>No. of Entries</span>
                        <Dropdown placeholder='Select page entries' options={entries} selection value={this.state.rowsPerPage} name="rowsPerPage" onChange={this.handleSelectChange} />
                        <Pagination totalPages={Math.round(this.state.totalRecords / this.state.rowsPerPage)} className="table-pagination" onPageChange={this.handlePageChange} siblingRange={1} />
                    </div>
                </div>

            </div>
        )
    }
}


const columns = [
    {
        title: 'S No',
        key: 'sno'
    },
    {
        title: 'Payout Date',
        key: 'order_initiate_date'
    },
    {
        title: 'Order Start Date',
        key: 'payout_initiated_date'
    },
    {
        title: 'Order End Date',
        key: 'payout_completed_date'
    },
    {
        title: 'Referral Count',
        key: 'referral_count'
    },
    {
        title: 'Paid Amount',
        key: 'commission_amount'
    },
];

const entries = [
    { key: 10, value: 10, text: '10' },
    { key: 25, value: 25, text: '25' },
    { key: 50, value: 50, text: '50' },
    { key: 100, value: 100, text: '100' }
]

