import React from "react";
import Env from '../../services/Env';
import moment from 'moment';
import "../../assets/css/dashboard.css";
import StorageConfiguration from "../../services/StorageConfiguration";
import { Table } from 'semantic-ui-react'
import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";
import NoRecords from "../../components/common/NoRecords";

const override = css`
display: block;
margin: 0 auto;
border-color: #1890ff;
text-align: center;
position: relative;
`;

class Dashboard extends React.Component {

    state = {
        dataSource: [],
        amountEarned: 0,
        referralCount: 0,
        totalSaleAmount: 0,
        loading: true,
        paidAmount: 0,
        unPaidAmount: 0
    };

    componentDidMount() {
        this.partnerId = StorageConfiguration.sessionGetItem('id');
        this.getRecentData()
        this.getDashboardCounts()
    }

    getRecentData() {
        const params = {
            page: 1,
            rowsPerPage: 25,
            partner_id: this.partnerId,
            filters: true,
            payment_status: 'completed'
        }
        const dataSource = []
        this.setState({ loading: true })
        const getRecentData = Env.get('partner/getpartnerreferallist', params);
        getRecentData.then(response => {
            const data = response.data.response.partner_referallist.data;
            // const totalRecords = response.data.response.partner_referallist.total;
            data.forEach((element, index) => {
                dataSource.push({
                    sno: (response.data.response.partner_referallist.from + index),
                    payment_id: element.order_id,
                    order_initiated_date: moment(element.created_at).format('DD-MM-yyyy hh:mm A'),
                    order_completed_date: element.order_completed_date !== null ? moment(element.order_completed_date).format('DD-MM-yyyy hh:mm A') : '',
                    coupon_code: [element.promocode_data.toUpperCase()],
                    payment_status: element.payment_status,
                    partner_name: element.users_firstname + ' ' + element.users_lastname,
                    student_last_name: element.student_first_name !== null ? (element.student_first_name + ' ' + element.student_last_name) : '',
                    product_type: element.product_type,
                    product_name: element.product_title,
                    sale_amount: element.final_price,
                    commission_amount: (element.commission_rate * element.final_price) / 100,
                    paid_status: element.paid_status,
                    key: index + 1
                })
            })
            const slicedData = dataSource.slice(0, 5)
            this.setState({ dataSource: slicedData, loading: false })
        })
    }

    getDashboardCounts() {
        const params = {
            partner_id: this.partnerId
        }
        const getCardCount = Env.get('partner/getsumofvalues', params);
        getCardCount.then(response => {
            const data = response.data.response.partner_values;
            this.setState({
                amountEarned: data[1].commisoin_rate, referralCount: data[0].referal_count, totalSaleAmount: data[2].sum_sale_amount, paidAmount: data[2].total_paid_amount,
                unPaidAmount: data[3].total_unpaid_amount
            })
            StorageConfiguration.sessionSetItem('earnedAmount', this.state.amountEarned);
        })
    }


    render() {

        const columns = [
            {
                title: 'S No',
                key: 'sno'
            },
            {
                title: 'Payment ID',
                key: 'payment_id'
            },
            {
                title: 'Order Initiated Date',
                key: 'order_initiated_date'
            },
            {
                title: 'Order Completed Date',
                key: 'order_completed_date'
            },
            {
                title: 'Coupon Code',
                key: 'coupon_code'
            },
            {
                title: 'Payment Status',
                key: 'payment_status'
            },
            // {
            //     title: 'Partner Name',
            //     key: 'partner_name'
            // },
            {
                title: 'Student Name',
                key: 'student'
            },
            {
                title: 'Product Name',
                key: 'product_name'
            },
            {
                title: 'Sale Amount',
                key: 'sale_amount'
            },
            {
                title: 'Commission Amount',
                key: 'commission_amount'
            },
            {
                title: 'Paid Status',
                key: 'paid_status'
            }
        ];
        return (<div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><span>Home</span></li>
                    <li className="breadcrumb-item active" aria-current="page">Dashboard</li>
                </ol>
            </nav>

            <div className="container-fluid">
                <div className="row justify-content-md-center">
                    <div className="col col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <span className="card-title">EARNED AMOUNT</span>
                                <h6 className="card-subtitle mt-1 text-muted">₹ {Math.round(this.state.amountEarned)}</h6>
                            </div>
                        </div>
                    </div>

                    <div className="col col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <span className="card-title">OVREALL REFERRAL</span>
                                <h6 className="card-subtitle mt-1 text-muted">{this.state.referralCount} nos.</h6>
                            </div>
                        </div>
                    </div>

                    <div className="col col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <span className="card-body-content-left">
                                    <span className="card-title">PAID AMOUNT</span>
                                    <h6 className="card-subtitle mt-1 text-muted">₹ {Math.round(this.state.paidAmount)}</h6>
                                </span>
                                <span className="card-body-content-right">
                                    <span className="card-title">UNPAID AMOUNT</span>
                                    <h6 className="card-subtitle mt-1 text-muted text-right">₹ {Math.round(this.state.unPaidAmount)}</h6>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">RECENT REFERRALS</li>
                </ol>
            </nav>

            {/* {this.state.loading === true && <ScaleLoader css={override} color={"#153659"} loading={true} />} */}

            <Table>
                <Table.Header>
                    <Table.Row>
                        {
                            columns.map((item, index) => <Table.HeaderCell key={index}>{item.title}</Table.HeaderCell>)
                        }
                    </Table.Row>
                </Table.Header>

                {
                    this.state.dataSource.length !== 0 ? <Table.Body>
                        {
                            this.state.dataSource.map((item, index) => <Table.Row key={index}>
                                <Table.Cell>{item.sno}</Table.Cell>
                                <Table.Cell>{item.payment_id}</Table.Cell>
                                <Table.Cell>{item.order_initiated_date}</Table.Cell>
                                <Table.Cell>{item.order_completed_date}</Table.Cell>
                                <Table.Cell>{item.coupon_code}</Table.Cell>
                                <Table.Cell>{item.payment_status}</Table.Cell>
                                {/* <Table.Cell>{item.partner_name}</Table.Cell> */}
                                <Table.Cell>{item.student_last_name}</Table.Cell>
                                <Table.Cell>{item.product_name}</Table.Cell>
                                <Table.Cell>{item.sale_amount}</Table.Cell>
                                <Table.Cell>{item.commission_amount.toPrecision(4)}</Table.Cell>
                                <Table.Cell>{item.paid_status}</Table.Cell>
                            </Table.Row>)
                        }
                    </Table.Body> : <Table.Body>
                        <Table.Cell colSpan="11">
                            {this.state.loading === false && <NoRecords />}
                            {this.state.loading === true && <ScaleLoader css={override} color={"#153659"} loading={true} />}
                        </Table.Cell>
                    </Table.Body>
                }



            </Table>


        </div>)
    }
}


export default Dashboard;