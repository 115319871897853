import React, { Component } from 'react'
import { Form, Button, Image, FormField } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom';
import Env from '../../services/Env';
import StorageConfiguration from "../../services/StorageConfiguration";
import toastr from 'toastr';
import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";
import '../../assets/css/user-profile.css'

const override = css`
display: block;
border-color: #1890ff;
text-align: center;
position: relative;
`;

export default class UserProfile extends Component {
    constructor() {
        super()
        this.state = {
            first_name: '',
            last_name: '',
            email_id: '',
            // role: 'partner',
            mobile_number: '',
            profile_image: '',
            commission_rate: '',
            loading: false
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.getProfileData()
    }

    getProfileData() {
        const params = {};
        const userId = StorageConfiguration.sessionGetItem('id')
        this.setState({ loading: true })
        const getData = Env.get(`users/${userId}/edit`, params);
        getData.then(response => {
            const data = response.data.response.user;
            this.setState({
                first_name: data.first_name,
                last_name: data.last_name,
                email_id: data.email_id,
                mobile_number: data.mobile_number,
                profile_image: data.profile_image,
                commission_rate: data.commission_rate,
                loading: false
            })
        }, err=> {
            toastr.error('Failed to retrieve data')
            this.setState({ loading: false })
        })
    }

    handleChange(event) {
        const name = event.target.dataset.hasOwnProperty("name") ? event.target.dataset.name : event.target.name;
        let value = event.target.value;
        if (value && (event.target.type === "radio" || event.target.type === "checkbox") && typeof value === "string" && (value.toLowerCase() === "true" || value.toLowerCase() === "false")) {
            value = value.toLowerCase() === "true" ? true : false;
        }
        this.updateFormFieldsState(name, value);
    }

    handleSelectChange(event, data) {
        const name = data.name;
        let value = data.value;
        if (value && (data.type === "radio" || data.type === "checkbox") && typeof value === "string" && (value.toLowerCase() === "true" || value.toLowerCase() === "false")) {
            value = value.toLowerCase() === "true" ? true : false;
        }
        this.updateFormFieldsState(name, value);
    }

    updateFormFieldsState(name, value) {
        this.setState({ [name]: value })
    }

    handleImageChange = (e, data) => {
        let formData = new FormData()
        formData.append('file', e.target.files[0]);
        formData.append('field_name', 'profile_image');
        formData.append('ebookId', '');
        //eslint-disable-next-line
        formData.append('originalName', data.value.replace(/^.*[\\/]/, ''));
        formData.append("file", e.target.files[0]);
        this.setState({ loading: true })
        const postData = Env.post(`images/upload/user`, formData);
        postData.then(response => {
            toastr.success("Image Uploaded Successfully !!")
            this.setState({ profile_image: response.data.response.image_name, loading: false })
        }, err => {
            toastr.error("Image Upload Failed !!")
            this.setState({ loading: false })
        })
    }

    handleSubmit() {
        const userId = StorageConfiguration.sessionGetItem('id')
        this.setState({ loading: true })
        const updateDate = Env.put(`users/${userId}`, this.state);
        updateDate.then(response => {
            toastr.success("Profile Updated Successfull !!")
            this.setState({ loading: false })
        }, err => {
            toastr.error(err.response.data.message.email_id ? err.response.data.message.email_id[0] : err.response.data.message.mobile_number[0])
            this.setState({ loading: false })
        })
    }

    render() {
        return (
            <div>
                <div>

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><span>User</span></li>
                            <li className="breadcrumb-item active" aria-current="page">Profile</li>
                        </ol>
                    </nav>

                    <div className="container-fluid">
                        <Form className="profile-form">

                            {this.state.loading === true && <ScaleLoader css={override} color={"#153659"} loading={true} />}

                            <Form.Input
                                onChange={this.handleChange}
                                name='first_name'
                                label='First name'
                                placeholder='John'
                                value={this.state.first_name}
                                maxLength="40"
                            />
                            <Form.Input
                                onChange={this.handleChange}
                                name='last_name'
                                label='Last name'
                                placeholder='Wesely'
                                value={this.state.last_name}
                                maxLength='40'
                            />
                            <Form.Input
                                onChange={this.handleChange}
                                name='email_id'
                                label='Email ID'
                                placeholder='john@yopmail.com'
                                value={this.state.email_id}
                                type='email'
                                maxLength='60'
                            />
                            {/* <Form.Select
                                name='role'
                                label='Role'
                                placeholder='Partner'
                                // options={roleOptions}
                                value={this.state.role}
                            /> */}

                            <FormField>
                                <label className="profile-image-label">Profile Image</label>
                                <Image name='profile_image' src={process.env.REACT_APP_ASSETS_URL + this.state.profile_image} alt="no_image" size='small' className="profile-image" />
                            </FormField>


                            <Form.Input
                                name="profile_image"
                                type="file"
                                onChange={this.handleImageChange}
                                accept=".jpg,.jpeg,.png"
                            />

                            <Form.Input
                                onChange={this.handleChange}
                                name='mobile_number'
                                label='Mobile Number'
                                placeholder='975875678'
                                value={this.state.mobile_number}
                                maxLength="10"
                            />

                            <Form.Input
                                name='commission_rate'
                                label='Commission Rate'
                                placeholder='Commission Rate'
                                value={this.state.commission_rate}
                                disabled
                            />

                            <Form.Field>
                                <NavLink to='/dashboard'><Button size='large'>Cancel</Button></NavLink>
                                <Button className="submit-button" onClick={this.handleSubmit} size='large' style={{ background: "#2185d0", color: '#fff' }}>Submit</Button>
                            </Form.Field>
                        </Form>
                    </div>

                </div>
            </div>
        )
    }
}