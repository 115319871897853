import React, { Component } from 'react';
import DashboardLogo from '../logo/DashboardLogo';
import { NavLink } from 'react-router-dom';
import '../../assets/css/sidebar.css';
import { Icon } from 'semantic-ui-react'

export default class LayoutSidebar extends Component {
    render() {
        return (
            <div>
               
                <div className="sidebar">
                    <a href="/dashboard" className="logo"><DashboardLogo /></a>
                    <NavLink to='/dashboard'><Icon name='dashboard'></Icon>Dashboard</NavLink>
                    <NavLink to='/referral/list'><Icon name="list"></Icon>Referral List</NavLink>
                    <NavLink to='/payout/history'><Icon name="history"></Icon>Payout History</NavLink>
                    <NavLink to='/reports'><Icon name="chart area"></Icon>Report</NavLink>
                    <div style={{position: 'fixed', bottom: 0, width: 'inherit', textAlign: 'center', color: '#fff', opacity: .5, padding: '5px'}}>v1.1.0</div>
                </div>
            </div>
        )
    }
}


