import React, { Component } from 'react';
import StorageConfiguration from '../../services/StorageConfiguration';
import '../../assets/css/header.css';
import toastr from 'toastr';
import { Icon, Popup, List, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';


export default class LayoutHeader extends Component {

    constructor() {
        super()
        this.state = {
            showPopup: false
        }
        this.handleShowPopup = this.handleShowPopup.bind(this)
    }

    handleShowPopup() {
        this.setState({ showPopup: !this.state.showPopup })
    }

    logOut = () => {
        this.setState({ showPopup: !this.state.showPopup })
        localStorage.clear();
        toastr.success('Logged Out Successfull !!')
    }
    render() {
        const name = StorageConfiguration.sessionGetItem('firstName') + ' ' + StorageConfiguration.sessionGetItem('lastName');
        const image = StorageConfiguration.sessionGetItem('profileImage');
        return (
            <div className="header">
                <div className="header-right">
                    <span>
                        <Image src={process.env.REACT_APP_ASSETS_URL+image} style={{width: '40px', height: '40px', cursor: 'pointer'}} as={Link} to="/user/profile" />
                    </span>
                        <Popup
                            content={
                                <List list size="large">
                                    <List.Item icon='user' content='Edit Profile' as={Link} to="/user/profile" onClick={() => this.handleShowPopup(false)}/>
                                    <List.Item icon='key' content='Change Password' as={Link} to="/change/password" onClick={() => this.handleShowPopup(false)}/>
                                    <List.Item icon='log out' content='Logout' onClick={this.logOut} as={Link} to="/login"/>
                                </List>
                            }
                            on='click'
                            onClose={() => this.handleShowPopup(false)}
                            onOpen={() => this.handleShowPopup(true)}
                            popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
                            trigger={<span onClick={this.handleShowPopup} className="header-text">{name}<Icon name="caret down" /></span>}
                            open={this.state.showPopup}
                        />
                </div>
            </div>
        )
    }
}
