import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import Loginpage from '../containers/auth/Loginpage';
import ForgotPassword from '../containers/auth/ForgotPassword';
import ReferralList from '../containers/referral-list/ReferralList';
import Dashboard from '../containers/dashboard/Dashboard';
import AuthenticatedRoute from './AuthenticatedRoute';
import LayoutHeader from '../components/layouts/Header';
import LayoutFooter from '../components/layouts/Footer';
import LayoutSidebar from '../components/layouts/Sidebar';
import Reports from '../containers/report/Reports';
import UserProfile from '../containers/profile/UserProfile';
import PayoutHistory from '../containers/payout-history/PayoutHistory';
import ChangePassword from '../containers/change-password/ChangePassword';

class Routes extends React.Component {
    render() {
        return (
            <div>
                <Router basename="/">
                    <Switch>
                        <Route path="/" name="Login" exact component={Loginpage} />
                        <Route path="/login" name="Login" exact component={Loginpage} />
                        <Route path="/forgot/password" name="Forgot Password" exact component={ForgotPassword} />
                        <div>
                            <LayoutSidebar />
                            <LayoutHeader />
                            <div style={styles.content}>
                                <AuthenticatedRoute path="/dashboard" name="Dashboard" exact component={Dashboard} />
                                <AuthenticatedRoute path="/referral/list" name="Referral List" exact component={ReferralList} />
                                <AuthenticatedRoute path="/reports" name="Report" exact component={Reports} />
                                <AuthenticatedRoute path="/user/profile" name="User Profile" exact component={UserProfile} />
                                <AuthenticatedRoute path="/payout/history" name="Payout History" exact component={PayoutHistory} />
                                <AuthenticatedRoute path="/change/password" name="Change Password" exact component={ChangePassword} />
                            </div>

                            <LayoutFooter />
                        </div>
                    </Switch>
                </Router>
            </div>
        )
    }
}


export default Routes;

const styles = {
    content: { margin: '0 0px 0px 230px', padding: '1px 16px 50px' },
    innerLayout: { minHeight: '100vh' },
    layout: { background: '#001529' }
};

