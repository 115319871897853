import {createStore, applyMiddleware} from 'redux';
import {createLogger} from 'redux-logger';

var logger = createLogger({
    collapsed : true
});

const configureStore = () => 
    createStore(
    applyMiddleware(logger)
    );

export default configureStore;