import React, { Component } from 'react';
import { Button, Divider, Form, Input, Icon } from 'semantic-ui-react'
import Logo from '../../components/logo/Logo'
import '../../assets/css/login-page.css';
import Env from '../../services/Env';
import toastr from "toastr";
import StorageConfiguration from "../../services/StorageConfiguration";
import _ from 'lodash';
import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";

const override = css`
display: block;
margin: 0 auto;
border-color: #1890ff;
text-align: center;
position: relative;
top: 25px;
`;

export default class Loginpage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fields: {
                email_id: '',
                password: ''
            },
            loading: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const name = event.target.dataset.hasOwnProperty("name") ? event.target.dataset.name : event.target.name;
        let value = event.target.value;
        if (value && (event.target.type === "radio" || event.target.type === "checkbox") && typeof value === "string" && (value.toLowerCase() === "true" || value.toLowerCase() === "false")) {
            value = value.toLowerCase() === "true" ? true : false;
        }
        this.updateFormFieldsState(name, value);
    }

    updateFormFieldsState(name, value) {
        if (this && name) {
            let fields = JSON.parse(JSON.stringify(this.state.fields));
            fields = _.set(fields, name, value);
            this.setState({ fields: fields })
        }
    }

    handleSubmit() {
        this.setState({ loading: true })
        const postData = Env.post('login', this.state.fields);
        postData.then(response => {
            const data = response.data
            if (data.status === 200) {
                if (data.response.user.role_name === 'Partner') {
                    StorageConfiguration.sessionSetItem('isloggedIn', true);
                    StorageConfiguration.sessionSetItem('id', data.response.user.id);
                    StorageConfiguration.sessionSetItem('token', data.response.access_token);
                    StorageConfiguration.sessionSetItem('role', data.response.user.role_permission_id);
                    StorageConfiguration.sessionSetItem('role_permission', data.response.user.role_master);
                    StorageConfiguration.sessionSetItem('firstName', data.response.user.first_name);
                    StorageConfiguration.sessionSetItem('lastName', data.response.user.last_name);
                    StorageConfiguration.sessionSetItem('profileImage', data.response.user.profile_image);
                    toastr.success("Login Successfull !!")
                    this.props.history.push('/dashboard')
                }
                else {
                    toastr.error("You are not a Partner")
                }
            }
            this.setState({ loading: false })
        }).catch(error => {
            this.setState({ loading: true })
            toastr.error("Failed to Login")
            this.setState({ loading: false })
        })
    }

    componentDidMount() {
        var token = StorageConfiguration.sessionGetItem('token');
        if(token) {
            this.props.history.push('/dashboard')
        }
    }

    render() {
        const { fields } = this.state;
        return (
            <div className="main">
                <div className="center-view">
                    <Logo />
                </div>
                <Divider />
                {this.state.loading === true && <ScaleLoader css={override} color={"#153659"} loading={true} />}
                {this.state.loading === false && <div className="card-div">
                    <div className="inner-card-div">
                        <div className="card-header">
                            <span className="card-title">To continue, Login to CareersCloud</span>
                        </div>
                        <div className="center-view">
                            <Form onSubmit={this.handleSubmit}>
                                <Form.Field>
                                    <Input placeholder='Email ID' type="email" onChange={this.handleChange} value={fields.email_id} name="email_id" required iconPosition='left' icon={<Icon name='mail' />} />
                                </Form.Field>
                                <Form.Field>
                                    <Input placeholder='Password' type="password" onChange={this.handleChange} value={fields.password} name="password" required iconPosition='left' icon={<Icon name='key' />} />
                                </Form.Field>
                                <Form.Field>
                                    {/* <a className="forgot-link" href="/forgot/password"> Forgot your password ?</a> */}
                                </Form.Field>
                                <Form.Field>
                                    <Button type='submit' className="login-button" primary size="large">LOGIN</Button>
                                </Form.Field>
                            </Form>
                        </div>

                    </div>
                </div>}
                <div className="footer">
                    <p>Copyright &copy; 2021 Careers Cloud. All rights reserved</p>
                </div>
            </div>
        )
    }
}
