import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from './Auth'


function AuthenticatedRoute(props) {
  const { component: Component, path } = props;
  return (
    <Route
      exact
      path={path}
      render={props => {
        if (!auth.isAuthenticated()) {
          return <Redirect push to="/login" />
        }
        return <Component {...props} />
      }}
    />
  );
}

export default AuthenticatedRoute;
