import React, { Component } from 'react';
import moment from 'moment';
import '../../assets/css/footer.css'

export default class LayoutFooter extends Component {
    state = {
        today: new Date()
    }
    render() {
        return (
            <div className="footer">
                <p>Copyright &copy; {moment(this.state.today).format('YYYY')} Careers Cloud. All rights reserved</p>
            </div>
        )
    }
}
